/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

import '@fortawesome/fontawesome-free/css/all.css';
export const onClientEntry = () => {
    const script = document.createElement("script");
    script.src = "https://unpkg.com/leaflet@1.9.3/dist/leaflet.js";
    script.integrity = "sha256-WBkoXOwTeyKclOHuWtc+i2uENFpDZ9YPdf5Hf+D7ewM=";
    script.crossOrigin = "";
    document.body.appendChild(script);
};
